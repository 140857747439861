<template>
  <div class="content">
    <div class="top-banner">
      <img
        src="@/assets/images/mhome_banner.png"
        alt=""
        srcset=""
        class="home_banner"
      >
      <div
        class="btn top-banner-btn"
        @click="handleToWork()"
      >
        立即沟通
      </div>
    </div>
    <div class="banner">
      <div class="banner-title">
        师资匹配率最高可达99%<br />
        极大降低退费率
      </div>
      <img
        src="@/assets/images/msammy_banner.png"
        alt=""
        srcset=""
        class="banner_img"
      >
      <div class="text-wrap">
        <div
          class="text-item"
          v-for="(item,index) in text1Data"
          :key="index"
        >
          <div class="top">
            <span>{{item.top_text}}</span>
          </div>
          <div class="bottom">{{item.bottom_text}}</div>
        </div>
      </div>
      <div
        class="btn banner-btn"
        @click="handleToWork()"
      >
        免费体验
      </div>
    </div>
    <div class="banner">
      <div class="banner-title">
        零匹配成本及低于市场价的课时费<br />
        提高课程利润率
      </div>
      <div class="text-wrap text-wrap-FFB966">
        <div class="title">当前的匹配现状</div>
        <div class="text-item">
          <div class="top">
            <span>额外的推荐费用</span>
          </div>
          <div class="bottom">300-500元不等</div>
        </div>
        <div class="text-item">
          <div class="top">
            <span>高额的课时费用</span>
          </div>
          <div class="bottom">北上广深院校：180元/小时起</div>
          <div class="bottom">985/211院校：200元/小时起</div>
          <div class="bottom">艺术类专业：300元/小时起</div>
          <div class="bottom">其他冷门专业：250元/小时起</div>
        </div>
      </div>
      <div class="text-wrap text-wrap-FFB966 text-wrap-94C5FF">
        <div class="title">我们的服务优势</div>
        <div class="text-item">
          <div class="top">
            <span>额外的推荐费用</span>
          </div>
          <div class="bottom">
            <span>0元</span>
          </div>
        </div>
        <div class="text-item">
          <div class="top">
            <span>高额的课时费用</span>
          </div>
          <div class="bottom">
            <span>远低于市场的课时费</span>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="banner-title">
        大幅提升课程口碑<br />
        提高第二年的招生率
      </div>
      <img
        src="@/assets/images/mbanner_img.png"
        alt=""
        srcset=""
        class="banner_img"
      >
      <div class="text-wrap">
        <div
          class="text-item"
          v-for="(item,index) in text1Data"
          :key="index"
        >
          <div class="top top-FFB966">
            <span>{{item.top_text}}</span>
          </div>
          <div class="bottom">{{item.bottom_text}}</div>
        </div>
      </div>
      <div
        class="btn banner-btn"
        @click="handleToWork()"
      >
        免费体验
      </div>
    </div>
    <div class="tips">
      <span class="title">可适当减少班主任人员配置，降低课程服务成本</span>
      <span>以您机构的名义，承接学员全程的专业课课程安排及监督，分配
        专属的学管师与学生沟通对接，全方面的保障学员的课程进度及
        质量；
      </span>
      <span>
        匹配、对接、授课、回访、督学…完善的内容及服务形态，一站
        式解决您的专业课全链路流程。
      </span>
      <div
        class="btn tips-btn"
        @click="handleToWork()"
      >
        免费体验
      </div>
    </div>
    <div class="banner">
      <div class="banner-title">
        他们都在用研盒<br />
        <span>更好地服务，铸就更好地信任</span>
      </div>
      <div class="count-wrap">
        <div
          class="count-item"
          v-for="(item,index) in text3Data"
          :key="index"
        >
          <span class="top">{{item.top_text}}+</span>
          <span class="bottom">{{item.bottom_text}}</span>
        </div>
      </div>
      <img
        src="@/assets/images/mcomcany_banner_bcg.png"
        alt=""
        srcset=""
        class="banner_img"
      >
    </div>
    <CompanyChoose
      :visible="CompanyChooseDialog"
      :content="CompanyChooseArray"
      @close="CompanyChooseDialog=false"
    />
  </div>
</template>

<script>

import { setStorage, getStorage, crmUrl } from "@/utils/helper";
import CompanyChoose from '@/components/MCompanyChoose.vue'
export default {
  components: {
    CompanyChoose
  },
  data() {
    return {
      CompanyChooseDialog: false,
      CompanyChooseArray: [],
      text1Data: [
        {
          top_text: "自主研发订单对接系统",
          bottom_text: "在线实时提交服务需求，一键即可查看详细服务记录"
        },
        {
          top_text: "师资覆盖超600所高校",
          bottom_text: "匹配需求提交后即可快速响应，极大缩短匹配周期"
        },
        {
          top_text: "满足个性化辅导需求",
          bottom_text: "海量的师资储备，总是可以满足您对课程的质量要求"
        },
        {
          top_text: "只招生不退费",
          bottom_text: "最高可达99%的匹配率，严格杜绝因无匹配而退费的情况"
        }
      ],
      text2Data: [
        {
          top_text: "完善的师资招募流程",
          bottom_text: "招募、筛选、培训、试讲等流程全面把控，严控课程质量"
        },
        {
          top_text: "超详细的课程计划定制",
          bottom_text: "课前制定超详细课程计划，时刻把控课程进度及内容"
        },
        {
          top_text: "课程内容实时回放",
          bottom_text: "多对一平台授课，全程监管，课后回放"
        },
        {
          top_text: "课后评价系统",
          bottom_text: "课后实时确认及评价系统，确保课程口碑"
        }
      ],
      text3Data: [
        {
          top_text: 600,
          bottom_text: "优质客户"
        }, {
          top_text: "10万",
          bottom_text: "服务学员"
        }, {
          top_text: "70万",
          bottom_text: "累计师资"
        }, {
          top_text: '95%',
          bottom_text: "匹配率"
        },
      ]
    };
  },
  created() {

  },
  computed: {
    isUser() {
      return getStorage('admin_user')
    }
  },
  methods: {
    handleToWork() {
      if (this.isUser) {
        let companyList = this.isUser.companys
        if (companyList.length == 1) {
          let str = crmUrl()
          window.open(`${str}/overview?userInfo=${JSON.stringify(this.isUser)}&cid=${companyList[0].id}`)
        } else {
          this.CompanyChooseArray = companyList
          this.CompanyChooseDialog = true
        }
      } else {
        this.$router.push(`/msubmit/register`)
      }

    }
  }

};
</script>

<style lang="less" scoped>
.content {
  margin-top: 1.17rem;
  .top-banner {
    position: relative;
    margin: 0 auto;
    height: 11.87rem;
    .home_banner {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .top-banner-btn {
      position: absolute;
      padding: 0.44rem 0.88rem;
      top: 7.47rem;
      left: 1.17rem;
    }
  }
  .banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.51rem;
    padding: 0px 1.07rem;
    .banner-title {
      font-size: 1.47rem;
      font-weight: 800;
      text-align: center;
      margin-bottom: 0.67rem;
      span {
        color: #999999;
        font-size: 0.67rem;
      }
    }
    .banner_img {
      width: 91.47%;
    }
    .text-wrap {
      padding: 0px 1.07rem;

      margin-top: 1.07rem;
      .text-item {
        & + .text-item {
          margin-top: 1.07rem;
        }
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .top {
          margin-bottom: 0.53rem;
          span {
            background: url("https://upload.kaoyanbox.net/66138a204c2c8ae2f3e761fb79862cd9.png")
              no-repeat;
            background-size: 100% 100%;
            font-size: 0.93rem;
            font-weight: 600;
            padding: 0px 6px;
          }
        }

        .bottom {
          & + .bottom {
            margin-top: 0.53rem;
          }
          padding: 0px 0.4rem;
          color: #999999;
          font-size: 0.8rem;
        }
      }
    }
    .text-wrap-FFB966 {
      width: 91.47%;
      /*      height: 16.8rem; */
      background: url("https://upload.kaoyanbox.net/ef21770c90ed93b13bf4663f50db9e1f.png")
        no-repeat;
      background-size: 100% 100%;
      padding-bottom: 1.07rem;
      .title {
        text-align: center;
        padding: 0.67rem 0px;
        font-size: 0.93rem;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 0.8rem;
      }
      .text-item {
        .top {
          span {
            background: url("https://upload.kaoyanbox.net/7947ef60efa87e4fea6a0c0df77c16c1.png")
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    .text-wrap-94C5FF {
      background: url("https://upload.kaoyanbox.net/b733b9ef674fbb27053c48094a002621.png")
        no-repeat;
      background-size: 100% 100%;
      .text-item {
        .top {
          span {
            background: url("https://upload.kaoyanbox.net/66138a204c2c8ae2f3e761fb79862cd9.png")
              no-repeat;
            background-size: 100% 100%;
          }
        }
        .bottom {
          span {
            background-color: #83bcff;
            padding: 0.13rem 0.4rem;
            color: #fff;
            font-size: 0.67rem;
          }
        }
      }
    }
    .banner-btn {
      padding: 0.67rem 1.6rem;
      margin-top: 1.6rem;
    }
    .count-wrap {
      width: 91.47%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.07rem;
      .count-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
          font-weight: 600;
          font-size: 1.2rem;
          margin-bottom: 0.53rem;
        }
        .bottom {
          font-size: 0.8rem;
        }
      }
    }
  }
  .tips {
    margin-top: 3.73rem;
    padding: 1.07rem 1.23rem;
    background: url("https://upload.kaoyanbox.net/c1a328e33c7d7c23cb8ebea09a92180d.png")
      no-repeat;
    background-size: 100% 100%;

    span {
      color: #fff;
      display: inline-block;
      font-size: 0.8rem;
      & + span {
        margin-top: 1.07rem;
      }
    }
    .title {
      font-weight: 600;
      font-size: 1.07rem;
    }
    .tips-btn {
      width: 6.93rem;
      height: 2.57rem;
      margin: 0 auto;
      margin-top: 1.6rem;
      color: #1f86fe;
      background-color: #fff;
    }
  }
}
.btn {
  background: #1f86fe;
  border-radius: 3px;
  font-size: 0.88rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:active {
  background: #79b6fe;
}
</style>
